<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <router-link to="/">
        <h1>I.E Industrial</h1>
      </router-link>
      <v-spacer />
      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <!-- <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab> -->
          <v-tab
            to="/directiva"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Directiva
          </v-tab>
          <v-tab
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Administrativa y Financiera
          </v-tab>
          <v-tab
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Comunidad
          </v-tab>
          <v-tab
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Académica
          </v-tab>
          <v-tab
            href="http://ieindustrialtulua.edu.co/moodle/login/index.php"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Campus Virtual
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=573113886643&text=&source=&data="
        rel="noopener noreferrer"
        class="float"
        target="_blank"
        title="Info Campus Virtual"
      >
        <base-avatar-card
          icon="mdi-whatsapp"
        />
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        'Home',
        'About',
        'Contact',
        'Pro',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>

<style scoped>
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .ion-social-whatsapp:before {
    margin-top: 15px;
  }

  .float {
    animation: float_2558 7s linear infinite;
    transform-origin: 50% 50%;
  }

  @keyframes float_2558 {
    0% { transform: translateY(0) }
    14.28571% { transform: translateY(-6px) }
    28.57143% { transform: translateY(0) }
    100% { transform: translateY(0); }
  }

</style>
